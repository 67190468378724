"use client";
import ReactCountryFlag from "react-country-flag";

export interface LanguagesPreviewProps {
  languages: {
    code: string;
    flagCode: string;
    name: string;
    id: string;
  }[];
}

export const LanguagesPreview = ({ languages }: LanguagesPreviewProps) => {
  return (
    <div className="flex  gap-1 ">
      {languages.map((lang) => (
        <div
          key={lang.id}
          className="flex  h-[22px] w-fit !max-w-fit items-center gap-1 rounded-full border border-green-200 bg-green-50 px-2 py-1"
        >
          <ReactCountryFlag
            countryCode={lang.flagCode}
            svg
            style={{ width: "16px"}}
            title={lang.name}
          />
          <span>{lang.name}</span>
        </div>
      ))}
    </div>
  );
};
