import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/apps/sajilni2.0/src/components/admin/event-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sajilni2/web/sajilni2.0/apps/sajilni2.0/src/components/blur-image.tsx");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/apps/sajilni2.0/src/lib/ui/index.ts");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/sajilni2/web/sajilni2.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
