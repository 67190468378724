"use client";
import Link from "next/link";
import BlurImage from "@/components/blur-image";
import { cn, placeholderBlurhash } from "@sajilni/utils";
import { Event, WhiteLabel, EventTranslations, Language } from "database";
import { useTranslation } from "@sajilni/localization";
import {
  Clock4,
  MapPin,
  Globe,
  Languages,
  Link as LinkIcon,
} from "lucide-react";
import moment from "moment";
import { LanguagesPreview } from "@sajilni/localization";
import { Badge } from "@/lib/ui";
import { LOCAL_HOSTNAME } from "@/constants";
export enum Types {
  PUBLISHED = "Published",
  DRAFT = "Draft",
  ENDED = "Ended",
}
export default function EventCard({
  data,
  languages,
}: {
  data: Event & EventTranslations & { whiteLabel: WhiteLabel | null };
  languages?: Language[];
}) {
  const { t } = useTranslation("sajilni2.0");

  const url = `${data.whiteLabel?.subdomain}.${process.env["NEXT_PUBLIC_ROOT_DOMAIN"]}/event/${data.slug}`;

  let eventStatus =
    data?.endDate && new Date().getTime() > new Date(data?.endDate).getTime()
      ? Types.ENDED
      : data?.published
      ? Types.PUBLISHED
      : Types.DRAFT;
  return (
    <div className="relative rounded-lg border border-gray-100 shadow-sm transition-all hover:shadow-md">
      <Link
        href={data.published ? `/event/${data.id}` : `/event/${data.id}/edit`}
        className="flex flex-col overflow-hidden rounded-lg"
      >
        <div className="relative h-[195px] overflow-hidden">
          <BlurImage
            alt={data.name ?? "Card thumbnail"}
            width={500}
            height={400}
            className="h-full w-full object-cover"
            src={data.image ?? "/placeholder.png"}
            placeholder="blur"
            blurDataURL={data.imageBlurhash ?? placeholderBlurhash}
          />
        </div>
      </Link>

      <div className="flex flex-col gap-2 p-4">
        <Link
          href={data.published ? `/event/${data.id}` : `/event/${data.id}/edit`}
          className="flex flex-col gap-2"
        >
          <h3 className="my-0 truncate text-xl font-semibold tracking-wide">
            {data.name}
          </h3>
          <div className="text-primary flex items-center gap-2">
            <Clock4 className="h-5 w-5 text-gray-500" />
            <p className="text-sm font-normal leading-snug ">
              {moment(data.startDate).utc().format("DD")}
              {data?.endDate != null ? ", " : " "}
              {data?.endDate != null
                ? moment(data?.endDate).utc().format("DD MMMM YYYY")
                : null}{" "}
              | Starts {moment(data.startDate).utc().format("hh:mm A")}
            </p>
          </div>
          <div className="text-primary flex items-center gap-2">
            <MapPin className="h-5 w-5 text-gray-500" />
            {data?.venue?.[0] ? (
              <p className="text-sm font-normal leading-snug">
                {data.venue[0]?.name && data.venue[0]?.name}{" "}
                {data.venue[0]?.cityName &&
                  ", " + data.venue[0]?.cityName + " | "}{" "}
                {data.venue[0]?.countryName}
              </p>
            ) : (
              <p className="text-sm font-normal leading-snug">N/A</p>
            )}
          </div>
          <div className="text-primary flex items-center gap-2">
            <Globe className="h-5 w-5 text-gray-500" />
            <p className="text-sm font-normal capitalize leading-snug">
              {t(data?.privacy?.toLocaleLowerCase())}
            </p>
          </div>
        </Link>
        {/* <div className="text-primary flex items-center gap-2">
          <LinkIcon className="h-5 w-5 text-gray-500" />
          <Link
            href={
              process.env["NEXT_PUBLIC_VERCEL_ENV"]
                ? `https://${url}`
                : `http://${data.whiteLabel?.subdomain}.${LOCAL_HOSTNAME}/event/${data.slug}`
            }
            target="_blank"
            rel="noreferrer"
            className="max-w-full truncate rounded-md bg-purple-100 px-2 py-1 text-sm font-medium text-gray-700 transition-colors hover:bg-purple-200"
          >
            {url} ↗
          </Link>
        </div> */}
        <div className="flex items-center justify-between">
          <div className="text-primary flex items-center gap-2">
            <Languages className="h-5 w-5 text-gray-500" />
            <div className="flex">
              <LanguagesPreview
                languages={
                  languages?.filter((lang) =>
                    data.languagesIds.includes(lang.id)
                  ) || []
                }
              />
            </div>
          </div>
          <Badge
            className={cn(
              "flex items-center justify-center !rounded-full !py-[2px]",
              data?.published
                ? "!border-green-500 !bg-green-50 !text-green-700"
                : "!border-pink-500 !bg-pink-50 !text-pink-700"
            )}
          >
            <div
              className={cn(
                "mr-2 h-[6px] w-[6px] rounded-full",
                data?.published ? "bg-green-500" : "bg-pink-500"
              )}
            ></div>
            {t(eventStatus)}
          </Badge>
        </div>
      </div>
    </div>
  );
}
